/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Sage = {
	// All pages
	'common': {
		// JavaScript to be fired on all pages
		init: function() {

			smoothScroll.init({
				speed: 400, // Integer. How fast to complete the scroll in milliseconds
				easing: 'easeInOutCubic', // Easing pattern to use
				updateURL: false, // Boolean. Whether or not to update the URL with the anchor hash on scroll
				offset: 0, // Integer. How far to offset the scrolling anchor location in pixels
			});

			var menuToggle = $("#js-mobile-menu").unbind();
			$("#js-navigation-menu").removeClass("show");

			menuToggle.on("click", function(e) {

				e.preventDefault();

				$("#js-navigation-menu").slideToggle(function(){
					if($("#js-navigation-menu").is(":hidden")) {
						$("#js-navigation-menu").removeAttr("style");
					}
				});

				if ( $("#js-navigation-menu").hasClass( 'open' ) ) {
					$("#js-navigation-menu").toggleClass('open');
				} else {
					setTimeout( function() { $("#js-navigation-menu").toggleClass('open'); }, 200 );
				}

			});

			if ( $('.toggler').length ) {

				$('.toggler').each( function() {

					var $toggler = $(this);
					var $targetId = $(this).data('target');
					var $target = $($targetId);

					$.each($target, function() {
						var $collapseInit = $(this).data('collapse-init');
						if ($collapseInit == 'collapse') {
							$(this).hide();
							$(this).addClass('collapsed');
						}
					});

					$(this).click(function(){

						if ( $(this).hasClass('finished') == false ) {

							$.each($target, function() {
								if ($(this).hasClass('collapsed')) {
									$(this).velocity('slideDown', {duration: 200});
								} else {
									$(this).velocity('slideUp', {duration: 200});
								}
								$(this).toggleClass('collapsed');
							});

							var endThis = $(this).data('end-this');

							if ( endThis == 'finish' ) {

								$(this).addClass('finished');

							} else if ( endThis == 'hide' ) {

								$(this).velocity({
									opacity: 0
								},{
									complete: function(elements) {
										$(elements[0]).css('display', 'block');
										$(elements[0]).velocity('slideUp', {duration: 200});
									},
									duration: 200
								});

							} else if ( endThis == 'replace' ) {

								var replacement = $(this).data('end-replacement');
								var $this = $(this);
								var parent = $(this).parent();

								parent.fadeOut(200, function(){
									$this.replaceWith(replacement);
									parent.fadeIn(200);
								});

							}

							var formFocus = $(this).data('form-focus');

							if ( $(formFocus).length ) {
								setTimeout(function() { $(formFocus).focus(); }, 400);
							}

						}

					});

				});
			}

			$(document).on( 'click', '.lity .lity-content', function(event) {
				$('[data-lity-close]', window.parent.document).trigger('click');
			});

			// Add Parameters to YouTube Embeds
			$('iframe[src^="https://www.youtube.com"]').each(function() {
				var url = $(this).attr("src")
				$(this).attr("src",url.substring(0,url.indexOf('?')) + '?color=white&controls=1&iv_load_policy=3&modestbranding=1&rel=0&showinfo=0')
			});

		},
		// JavaScript to be fired on all pages, after page specific JS is fired
		finalize: function() {

		}
	},
	// Home page
	'home': {
		// JavaScript to be fired on the home page
		init: function() {

			$('#ident .ident-content').addClass('grow');
			$('#ident').delay(2800).fadeOut(2000);

		},
		// JavaScript to be fired on the home page, after the init JS
		finalize: function() {

			var identSVG = d3.select('#ns-ident');
			var identStrokeN = identSVG.selectAll('#ns-ident-stroke-n');
			var identStrokeS = identSVG.selectAll('#ns-ident-stroke-s');

			setTimeout( function() {

				identStrokeN
					.style('opacity', 1)
					.transition()
					.duration(2300)
					.ease(d3.easeSinOut)
					.attrTween("stroke-dasharray", function() {
						var len = this.getTotalLength();
						return function(t) { return (d3.interpolateString("0," + len, len + ",0"))(t); };
					});

			}, 500);

			setTimeout( function() {

				identStrokeS
					.style('opacity', 1)
					.transition()
					.duration(1800)
					.ease(d3.easeSinOut)
					.attrTween("stroke-dasharray", function() {
						var len = this.getTotalLength();
						return function(t) { return (d3.interpolateString("0," + len, len + ",0"))(t); };
					});

			}, 1000);

		}
	},
	// About us page, note the change from about-us to about_us.
	'about_us': {
		// JavaScript to be fired on the about us page
		init: function() {}
	}
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
	fire: function(func, funcname, args) {
		var fire;
		var namespace = Sage;
		funcname = (funcname === undefined) ? 'init' : funcname;
		fire = func !== '';
		fire = fire && namespace[func];
		fire = fire && typeof namespace[func][funcname] === 'function';

		if (fire) {
			namespace[func][funcname](args);
		}
	},
	loadEvents: function() {
		// Fire common init JS
		UTIL.fire('common');

		// Fire page-specific init JS, and then finalize JS
		$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
			UTIL.fire(classnm);
			UTIL.fire(classnm, 'finalize');
		});

		// Fire common finalize JS
		UTIL.fire('common', 'finalize');
	}
};

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
